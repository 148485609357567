import React, { useEffect, Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import Logout from "./pages/auth/Logout";
import { useSelector } from "react-redux";
import TagManager from 'react-gtm-module'

require('dotenv').config();

if (process.env.NODE_ENV === 'production') {
  console.log = function () { };
}
if(window !== undefined)
// console.log("window.location.path",window.location.path)
window.location = "https://contentenablers.com"+window.location.pathname;
const ShopLanding = lazy(() => import("./pages/shop/ShopLanding"));
const AllCourses = lazy(() => import("./pages/shop/AllCourses"));
const Courses = lazy(() => import("./pages/shop/Courses"));
const CoursesNEW = lazy(() => import("./pages/shop/CoursesNEW"));

const SupplierCourses = lazy(() => import("./pages/partners/SupplierCourses"));

const PractitionerLanding = lazy(() => import("./pages/shop/PractitionerLanding"));


// shop pages
const SearchResult = lazy(() => import("./pages/shop/SearchResult"));

// product pages
const ProfessionalCourseDetail = lazy(() => import("./pages/shop/ProfessionalCourseDetail"));
const PractitionerCourseDetail = lazy(() => import("./pages/shop/PractitionerCourseDetail"));
const PractitionerBundleCourseDetail = lazy(() => import("./pages/shop/PractitionerBundleCourseDetail"));
const AerospaceBundleCourseDetail = lazy(() => import("./pages/shop/AerospaceBundleCourseDetail"));
const MedicalBundleCourseDetail = lazy(() => import("./pages/shop/MedicalBundleCourseDetail"));
const MovementOfGoods = lazy(() => import("./pages/partners/MovementOfGoods"));

// const MyAccount = lazy(() => import("./pages/shop/MyAccount"));
const MyAccountNEW = lazy(() => import("./pages/shop/MyAccountNEW"));
const LogIn = lazy(() => import("./pages/auth/LogIn"));
const SignUp = lazy(() => import("./pages/auth/SignUp"));

const ResetPassword = lazy(() => import("./pages/auth/ResetPassword"));
const ForgotPassword = lazy(() => import("./pages/auth/ForgotPassword"));

const AddCourseFromURL = lazy(() => import("./pages/other/AddCourseFromURL"));
const ThankYou = lazy(() => import("./pages/other/ThankYou"));


// other pages
const OurPartners = lazy(() => import("./pages/other/OurPartners"));
const Policy = lazy(() => import("./pages/other/PrivacyPolicy"));
const Terms = lazy(() => import("./pages/other/TermsConditions"));
const FAQ = lazy(() => import("./pages/other/FAQ"));
const CustomerSupport = lazy(() => import("./pages/other/CustomerSupport"));
const ContactUs = lazy(() => import("./pages/other/ContactUs"));

const Cart = lazy(() => import("./pages/shop/Cart"));


const SitemapBuilder = lazy(() => import("./pages/utility/SitemapBuilder"));

/** partners */
const PartnerLanding = lazy(() => import("./pages/partners/PartnerLanding"));

const NotFound = lazy(() => import("./pages/other/NotFound"));

const CourseTabs = lazy(() => import("./components/CourseTabs"));


let GTM_TAG_ID = '';

switch (window.PARTNER_TYPE) {
  case "shop":
    GTM_TAG_ID = "GTM-KHDXVVL";
    break
  case "egadd":
    GTM_TAG_ID = "GTM-TPPJPCQ";
    break
  case "techuk":
    GTM_TAG_ID = "GTM-K8XHZV3";
    break
  case "ieit":
    GTM_TAG_ID = "GTM-5W8JV4R";
    break
  case "china":
    GTM_TAG_ID = "GTM-KR44CKP";
    break
  case "shop-landing":
    GTM_TAG_ID = "GTM-MNMHZF3";
    break
  case "livingston":
    GTM_TAG_ID = "GTM-5HNX9WP";
    break
  case "sgs":
    GTM_TAG_ID = "GTM-W2XX7J8";
    break
  case "zte":
    GTM_TAG_ID = "GTM-NV9T3PD";
    break
  case "abcd":
    GTM_TAG_ID = "GTM-NV9T3PD";
    break
}

console.log("PARTNER_TYPE", window.PARTNER_TYPE);



const App = props => {

  const partnerTypeState = useSelector(state => state.partnerTypeState);

  useEffect(() => {
    TagManager.initialize({
      gtmId: GTM_TAG_ID
    })
  }, [])

  return (
    partnerTypeState.partnerType === "shop"?
      <p>Redirecting...</p> :
    <ToastProvider placement="bottom-left">
      <BreadcrumbsProvider>
        <Router>
          <ScrollToTop>
            <Suspense
              fallback={
                <div className="flone-preloader-wrapper">
                  <div className="flone-preloader">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              }
            >
              <Switch>
                {partnerTypeState.partnerType === "shop" || partnerTypeState.partnerType === "shop-landing" ?
                  <Route
                    exact
                    path={process.env.PUBLIC_URL + "/"}
                    component={ShopLanding}
                  />
                  : <Route
                    exact
                    path={process.env.PUBLIC_URL + "/"}
                    component={PartnerLanding}
                  />
                }
              {partnerTypeState.partnerType === "shop" &&
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/courses"}
                  component={CoursesNEW}
                />
              }
              {partnerTypeState.partnerType !== "shop" &&
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/courses"}
                  component={CoursesNEW}
                />
              }
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/movement-of-goods"}
                  component={MovementOfGoods}
                />

                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/all-courses"}
                  component={AllCourses}
                />

                {/* ========================== supplier ==========================*/}
                {partnerTypeState.partnerType === "shop" &&
                  <Route
                    exact
                    path={process.env.PUBLIC_URL + "/:supplierName/supplier"}
                    component={SupplierCourses}
                  />
                }
                {partnerTypeState.partnerType === "shop" &&
                  <Route
                    exact
                    path={process.env.PUBLIC_URL + "/:supplierName/supplier-appreciation"}
                    component={CoursesNEW}
                  />
                }
                {/* ===============================================================*/}
               

                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/courses-new"}
                  component={CoursesNEW}
                />

                {(partnerTypeState.partnerType === "ieit" || partnerTypeState.partnerType === "shop") &&
                  <Route
                    exact
                    path={process.env.PUBLIC_URL + "/trade-compliance-practitioner-series/:param?"}
                    component={PractitionerLanding}
                  />
                }

                <Route
                  path={process.env.PUBLIC_URL + "/courses/professional/:param"}
                  component={ProfessionalCourseDetail}
                />

                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/courses/practitioner/:param"}
                  component={PractitionerCourseDetail}
                />

                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/courses/practitioner-bundle/:rechargeHandle"}
                  component={PractitionerBundleCourseDetail}
                />
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/uk_export_practitioner"}
                  component={PractitionerBundleCourseDetail}
                />
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/us_export_practitioner"}
                  component={PractitionerBundleCourseDetail}
                />
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/courses/practitioner-bundle/aerospace-industry-training-pilot"}
                  component={AerospaceBundleCourseDetail}
                />
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/courses/practitioner-bundle/medical-pilot-training"}
                  component={MedicalBundleCourseDetail}
                />
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/our-partners"}
                  component={OurPartners}
                />

                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/terms-conditions"}
                  component={Terms}
                />

                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/privacy-policy"}
                  component={Policy}
                />

                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/faq"}
                  component={FAQ}
                />

                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/customer-support"}
                  component={CustomerSupport}
                />

                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/contact-us"}
                  component={ContactUs}
                />

                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/contact-us"}
                  component={ContactUs}
                />

                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/thank-you"}
                  component={ThankYou}
                />

                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/search/:searchParam"}
                  component={SearchResult}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/my-account"}
                  component={MyAccountNEW}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/login"}
                  component={LogIn}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/pages/practitioner/"}
                  render={() => { window.location.href = "/pages/practitioner/index.html" }}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/sign-up"}
                  component={SignUp}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/forgot-password"}
                  component={ForgotPassword}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/reset-password"}
                  component={ResetPassword}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/cart"}
                  component={Cart}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/add-course"}
                  component={AddCourseFromURL}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/not-found"}
                  component={NotFound}
                />
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/logout"}
                  component={Logout}
                />
                {
                  (process.env.NODE_ENV === 'development') && <Route
                    exact
                    path={process.env.PUBLIC_URL + "/sitemap-builder"}
                    component={SitemapBuilder}
                  />
                }

                {/* <Route
                exact 
                path={process.env.PUBLIC_URL+"/tabs"}
                component={CourseTabs}
                /> */}

                <Route exact component={NotFound} />
              </Switch>
            </Suspense>
          </ScrollToTop>
        </Router>
      </BreadcrumbsProvider>
    </ToastProvider>
  );
};

export default App;

